import React, { useState } from 'react';

import {
	IonCard,
	IonCardContent,
	IonItem,
	IonList,
	IonGrid,
	IonRow,
	IonCol,
	IonCardHeader,
	IonCardTitle,
	IonLabel,
	IonButton,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonFab,
	IonFabButton,
	IonIcon,
	IonModal,
	IonTextarea,
	IonToast,
	IonReorderGroup,
	IonPopover,
	IonListHeader
} from '@ionic/react';
import _ from 'lodash';
import { add, ellipsisHorizontal, trashOutline } from 'ionicons/icons';
import {
	QualifierEntity,
	QualifierValueEntity,
	QualifierValueEntities,
	QualifierValueEntityPatch,
	ServiceEntity,
	EventEntity,
	ObjectType,
	QualifierSelection,
	ObjectStatus,
	qualifierReducer
} from '../../../store';
import { QualifierValueListItem } from '../../components/Qualifier';
import { AddEditQualifierValue } from '.';
import { useHistory } from 'react-router-dom';
import { useCtx } from '../../../../config/hooks';
import { newEntity } from '../../../../app/utils';

export interface AddEditQualifierProps {
	service: ServiceEntity;
	event: EventEntity;
	qualifier?: QualifierEntity;
	finish: any;
}

export const AddEditQualifier: React.FC<AddEditQualifierProps> = props => {
	const [showQualifierValueModal, setShowQualifierValueModal] = useState(false);
	const [showSavedToast, setShowSavedToast] = useState(false);

	const ctx = useCtx<AddEditQualifierProps>({}); // TO DO: create and set (leadListConfig)
	const {
		config: [config]
	} = ctx;

	// create a new entity if one does not exist and track it in the component state
	const [qualifier, setQualifier] = useState<QualifierEntity>(
		props.qualifier || {
			...newEntity(ctx, ObjectType.Qualifier),
			serviceId: props.service.id,
			eventIds: [props.event.id],
			name: '',
			position: ctx.lead.qualifier.getNextPosition(props.service.id || '', [
				props.event.id || ''
			]),
			category: 'Questions',
			selection: QualifierSelection.Multiple
		}
	);

	const [editQualifierValue, setEditQualifierValue] = useState<
		QualifierValueEntity | undefined
	>(undefined);

	const [showPopover, setShowPopover] = useState<{
		open: boolean;
		event: Event | undefined;
	}>({
		open: false,
		event: undefined
	});

	const sortQualifierValues = (
		a: QualifierValueEntity,
		b: QualifierValueEntity
	) => {
		return (a.position as number) > (b.position as number)
			? 1
			: (b.position as number) > (a.position as number)
			? -1
			: 0;
	};

	const existingQualifier = props.qualifier
		? ctx.lead.qualifier.get(qualifier.id)
		: undefined;
	//const mutatedQualifier = ctx.lead.qualifier.mutated([qualifier.id])[0];

	//Load Qualifier Values
	const qualifierValues: QualifierValueEntities = ctx.lead.qualifierValue
		.allByQualifierId(qualifier.id)
		.sort(sortQualifierValues);

	const history = useHistory();

	const finish = () => {
		props.finish();
	};

	const closeQualifierValueModal = () => {
		//ctx.lead.qualifierValue.set();
		setEditQualifierValue(undefined);
		setShowQualifierValueModal(false);
	};

	const addValue = () => {
		//ctx.lead.qualifierValue.set();
		setEditQualifierValue(undefined);
		setShowQualifierValueModal(true);
	};

	const selectQualifierValue = (qualifierValue: QualifierValueEntity) => {
		//ctx.lead.qualifierValue.set(id);
		setEditQualifierValue(qualifierValue);
		setShowQualifierValueModal(true);
	};

	const onChange = (e: any) => {
		let targetValue =
			!('checked' in e.target) || e.target.checked ? e.target.value : '';

		//Validation
		let isValid = true;
		/*if (e.target.classList.contains('required')) {
			isValid = !(!targetValue || targetValue == '');
			isValid
				? e.target.classList.remove('has-error')
				: e.target.classList.add('has-error');
		}*/

		//Save Changes
		if (isValid) {
			let change: QualifierEntity = _.set(
				_.cloneDeep(qualifier),
				e.target.name,
				targetValue
			);
			//ctx.lead.qualifier.change(change);
			setQualifier(change);
		}
	};
	const save = (qualifierMutation?: QualifierEntity) => {
		//fix bug with missing category
		if (qualifier) {
			qualifier.category = qualifier.category || 'Questions';
		}

		ctx.lead.qualifier.write(ctx, {
			serviceId: props.service.id,
			qualifiers: [qualifierMutation || qualifier]
		});
	};

	const onSave = (e: any) => {
		save();
		finish();
	};
	const onDelete = (e: any) => {
		setShowPopover({
			open: false,
			event: undefined
		});
		ctx.lead.qualifier.writeDelete(ctx, qualifier.id);
		finish();
	};
	const onCancel = (e: any) => {
		//ctx.lead.qualifier.cancelChanges(qualifier.id);
		finish();
	};

	const onRenderQualifierValues = (e: any) => {
		console.log(`Moving item from ${e.detail.from} to ${e.detail.to}`);
		let draggedItem = qualifierValues.splice(e.detail.from, 1)[0];
		qualifierValues.splice(e.detail.to, 0, draggedItem);
		qualifierValues.sort(sortQualifierValues).map(function (o, i) {
			if (o) {
				let change: QualifierValueEntityPatch = _.set(
					{
						id: qualifier.id
					},
					'position',
					i + 1
				);
				console.log(change);
				ctx.lead.qualifierValue.patch(change);
			}
		});
		console.log(qualifierValues.sort(sortQualifierValues));
		//this.listItems = reorderArray(this.listItems, event.detail.from, event.detail.to);
		e.detail.complete();
	};

	//Define Qualifier list Items
	let qualifierListItems = qualifierValues
		.sort(sortQualifierValues)
		.map((qualifierValue: QualifierValueEntity) => {
			return (
				<QualifierValueListItem
					key={qualifierValue.id}
					onSelectQualifierValue={selectQualifierValue}
					qualifierValue={qualifierValue}
				/>
			);
		});

	return (
		<IonGrid>
			<IonRow>
				<IonCol>
					<IonToast
						isOpen={showSavedToast}
						onDidDismiss={() => setShowSavedToast(false)}
						message="Your qualifier has been saved."
						duration={300}
					/>
					<IonModal
						isOpen={showQualifierValueModal}
						backdropDismiss={false}
						onDidDismiss={() => closeQualifierValueModal()}
					>
						<AddEditQualifierValue
							service={props.service}
							qualifier={qualifier}
							qualifierValue={editQualifierValue}
							finish={closeQualifierValueModal}
						/>
					</IonModal>
					<IonPopover
						isOpen={showPopover.open}
						event={showPopover.event}
						onDidDismiss={e =>
							setShowPopover({ open: false, event: undefined })
						}
					>
						<IonList>
							<IonListHeader>Options</IonListHeader>
							<IonItem button onClick={onDelete}>
								<IonIcon icon={trashOutline} />
								Delete Qualifier
							</IonItem>
						</IonList>
					</IonPopover>
					<IonGrid>
						<IonRow>
							<IonCol size="12" sizeLg="8">
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>
											Qualifier Details
											{existingQualifier && (
												<IonButton
													class="ion-float-right"
													fill="clear"
													onClick={e =>
														setShowPopover({
															open: true,
															event: e.nativeEvent
														})
													}
												>
													<IonIcon icon={ellipsisHorizontal} />
												</IonButton>
											)}
										</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>
										<IonList>
											<IonItem hidden={true}>
												<IonLabel position="floating">Qualifier Type</IonLabel>
												<IonSelect
													name="selection"
													value={qualifier.selection}
													onIonChange={onChange}
													placeholder="Select One"
													className="required"
												>
													<IonSelectOption value="multiple">
														Multi Select
													</IonSelectOption>
												</IonSelect>
											</IonItem>
											<IonItem>
												<IonLabel position="floating">Qualifier Text</IonLabel>
												<IonInput
													placeholder="Enter Question Text"
													name="name"
													value={qualifier.name}
													onIonChange={onChange}
													className="required"
												></IonInput>
											</IonItem>
											<IonItem hidden={true}>
												<IonLabel position="floating">Category</IonLabel>
												<IonSelect
													placeholder="Select One"
													name="category"
													value={qualifier.category}
													onIonChange={onChange}
												>
													<IonSelectOption value="Questions">
														Questions
													</IonSelectOption>
													<IonSelectOption value="Products">
														Products
													</IonSelectOption>
													<IonSelectOption value="Services">
														Services
													</IonSelectOption>
													<IonSelectOption value="Follow-ups">
														Follow-ups
													</IonSelectOption>
												</IonSelect>
											</IonItem>
											<IonItem hidden={true}>
												<IonLabel position="floating">Description</IonLabel>
												<IonTextarea
													name="description"
													value={qualifier.description}
													onIonChange={onChange}
												></IonTextarea>
											</IonItem>
										</IonList>
									</IonCardContent>
								</IonCard>
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>
											<IonLabel>Selections</IonLabel>
										</IonCardTitle>
										<IonFab
											vertical="top"
											horizontal="end"
											onClick={() => addValue()}
										>
											<IonFabButton size="small">
												<IonIcon icon={add} />
											</IonFabButton>
										</IonFab>
									</IonCardHeader>
									<IonCardContent>
										{qualifierValues && qualifierValues.length > 0 ? (
											<IonReorderGroup
												onIonItemReorder={onRenderQualifierValues}
												disabled={false}
											>
												{qualifierListItems}
											</IonReorderGroup>
										) : (
											<IonLabel>
												<h3>
													There are no qualifier values. Please use the button
													above to add a qualifier value.
												</h3>
											</IonLabel>
										)}
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6" sizeLg="4">
								<IonButton color="light" onClick={onCancel}>
									Cancel
								</IonButton>
							</IonCol>
							<IonCol size="6" sizeLg="4" class="text-right">
								<IonButton
									//disabled={mutatedQualifier ? false : true}
									onClick={onSave}
								>
									Save
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
